import React from 'react'
import { graphql } from 'gatsby'

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/style.scss"
import "../styles/main.scss"

import BlockList from '../components/block-list/block-list';

class GlobalContactsPage extends React.Component {

  render() {
    var slotsList = this.props.data.allContentfulPage.nodes[0].slots;
    
    var page = this.props.data.allContentfulPage.edges[0].node;
    
    return (
      <Layout bannerImage={page.headerImage} pageTitle={page.headerTitle}>
         <SEO title={page.title} description={page.description.description} pathname={page.slug} keywords={page.keywords} image={page.headerImage.file.url}/>
        {slotsList.map((slot, index) => {
          return <section id={slot.contents[0].sectionId} className={slot.backgroundClass} key={index}>
            <BlockList data={slot.contents[0]}></BlockList>
          </section>
        })}
      </Layout >
    )
  }
}

export default GlobalContactsPage

export const pageQuery = graphql`
  query GlobalContactsTemplateQuery($locale: String!) {
    allContentfulPage(filter: {slug: {eq: "global-contacts"}, node_locale: {eq: $locale}}) {
      totalCount
      edges {
        node {
          id
          node_locale
          title
          keywords
          headerTitle
          description {
            description
          }
          slug
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid (maxWidth: 500, quality: 100)  {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
      nodes {
        slots {
          backgroundClass
          name
          contents {
            __typename
            ...on ContentfulSlotBlocksList{
              title
              sectionId
              description {
                internal {
                  content
                  description
                  ignoreType
                  mediaType
                }
              }
              topics {
                name
                
              }
              blocks {
                name
                backgroundClass
                body {
                  json
                }
              }
            }
            ... on ContentfulSlotCarousel {
              images {
                title
                colorInverted
                alignment
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
            ... on ContentfulSlotVideo {
              title
              videoFile {
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
            ... on ContentfulSlotSection {
             __typename
             sectionHeading
            title
            sectionId
            subTitle
            imagePosition
            isPrimary
            underlineHeader
            body {
              json
            }
            image {
              title
              file {
                url
                fileName
                contentType
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            backgroundClass
            imageWidth
            bodyWidth
            linkUrl
          
            }
            ... on ContentfulSlotCallToAction {
              title
              subTitle
              content {
                name
                listItems {
                  linkDescription
                  linkUrl
                  image {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
